var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBL0002521" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "LBLAPPLY", icon: "save" },
                            on: { btnClicked: _vm.acceptData },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-3 col-md-3 col-lg-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        label: "LBL0002522",
                        name: "request",
                      },
                      model: {
                        value: _vm.request,
                        callback: function ($$v) {
                          _vm.request = $$v
                        },
                        expression: "request",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-3 col-md-3 col-lg-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "제목",
                      },
                      model: {
                        value: _vm.regData.uploadTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.regData, "uploadTitle", $$v)
                        },
                        expression: "regData.uploadTitle",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-excel-upload", {
        attrs: {
          editable: _vm.editable,
          excelUploadInfo: _vm.excelUploadInfo,
          tabHeight: "660px",
          gridHeight: "630px",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }